import { AppBar, Button, IconButton, Menu, MenuItem, Avatar } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import createStyles from '@material-ui/core/styles/createStyles'
import withStyles from '@material-ui/core/styles/withStyles'
import AccountCircle from '@material-ui/icons/AccountCircle'
import MenuIcon from '@material-ui/icons/Menu'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty, isLoaded } from 'react-redux-firebase'
import { useHistory } from 'react-router-dom'

import { ApplicationState } from '../../core/store'
import logo from '../../resources/logo.png'
import Toolbar from './Toolbar'
import { requestLogin, requestLogout } from '../../core/store/auth'

interface AppAppBarProps {
  classes: any
  mobileOpen: boolean
  setMobileOpen: (mobileOpen: boolean) => void
}

const AppAppBar: React.FC<AppAppBarProps> = (props) => {
  const { classes, mobileOpen, setMobileOpen } = props
  const auth = useSelector((state: ApplicationState) => state.firebase!.auth)
  const dispatch = useDispatch()
  const history = useHistory()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    setAnchorEl(null)
    dispatch(requestLogout())
  }

  const handleLogin = () => {
    setAnchorEl(null)
    dispatch(requestLogin('/'))
  }

  const handleStudio = () => {
    setAnchorEl(null)
    history.push('/studio')
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const loggedIn = (): boolean => {
    return auth !== undefined && isLoaded(auth) && !isEmpty(auth)
  }

  const LoggedInButton = () => {
    return auth.photoURL ? <Avatar alt={auth.displayName || ''} src={auth.photoURL} /> : <AccountCircle />
  }

  return (
    <AppBar position="fixed" className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <div onClick={() => history.push('/')}>
          <img src={logo} className={classes.appLogo} alt=""></img>
        </div>
        {loggedIn() && (
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <LoggedInButton />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>Profil</MenuItem>
              <MenuItem onClick={handleStudio}>Studio</MenuItem>
              <MenuItem onClick={handleLogout}>Logg ut</MenuItem>
            </Menu>
          </div>
        )}
        {!loggedIn() && (
          <div>
            <Button onClick={handleLogin}>Logg inn</Button>
          </div>
        )}
      </Toolbar>
    </AppBar>
  )
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    title: {
      fontSize: 24,
    },

    toolbar: {
      justifyContent: 'space-between',
      backgroundColor: '#ffffff',
    },
    appLogo: {
      height: '25px',
      cursor: 'pointer',
    },
    left: {
      flex: 1,
    },
    leftLinkActive: {
      color: theme.palette.common.white,
    },
    right: {
      flex: 1,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    rightLink: {
      fontSize: 16,
      color: theme.palette.common.white,
      marginLeft: theme.spacing(3),
    },
    linkSecondary: {
      color: theme.palette.secondary.main,
    },
  })

export default withStyles(styles, { withTheme: true })(AppAppBar)
