import {
  connectRouter,
  routerMiddleware,
  RouterState
} from 'connected-react-router'
import { createBrowserHistory, History } from 'history'
import { FirebaseReducer, firebaseReducer } from 'react-redux-firebase'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { createFirestoreInstance, firestoreReducer } from 'redux-firestore'
import thunk from 'redux-thunk'

import { firebase } from '../firebase'

interface Profile {
  name: string
  email: string
}

interface Schema {}

export interface ApplicationState {
  router: RouterState
  firebase: FirebaseReducer.Reducer<Profile, Schema>
}

export const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    firebase: firebaseReducer,
    firestore: firestoreReducer
  })

export const history = createBrowserHistory()
const rootReducer = createRootReducer(history)

export const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  middleware: [routerMiddleware(history), thunk],
  preloadedState: {}
})

export const reactReduxFirebaseProps = {
  firebase: firebase.app,
  config: firebase.rfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
}

export type RootState = ReturnType<typeof rootReducer>
