import * as React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import createStyles from '@material-ui/core/styles/createStyles'
import { ThemeProvider, CssBaseline } from '@material-ui/core'
import { ui } from '@kwiz/core'
import { isLoaded } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../core/store'
import AppAppBar from './components/AppAppBar'
//import AppDrawer from './components/AppDrawer'
import Routes from './Routes'

interface AppProps {
  classes: any
}

const App: React.FC<AppProps> = ({ classes }) => {
  const auth = useSelector((state: ApplicationState) => state.firebase!.auth)
  const [mobileOpen, setMobileOpen] = React.useState(false)

  return (
    <>
      {!isLoaded(auth) ? (
        <span>Laster inn...</span>
      ) : (
        <ThemeProvider theme={ui.theme}>
          <CssBaseline />
          <div className={classes.root}>
            <AppAppBar mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
            {/*<AppDrawer mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />*/}
            <main className={classes.content}>
              <div className={classes.toolbar} />
              <Routes />
            </main>
          </div>
        </ThemeProvider>
      )}
    </>
  )
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    toolbar: theme.mixins.toolbar,
    content: {
      backgroundColor: '#ffe6cc',
      flexGrow: 1,
      minHeight: '100vh',
      padding: theme.spacing(3),
    },
  })

export default withStyles(styles, { withTheme: true })(App)
