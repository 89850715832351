import * as React from 'react'

import { Theme } from '@material-ui/core/styles/createMuiTheme'
import createStyles from '@material-ui/core/styles/createStyles'
import withStyles from '@material-ui/core/styles/withStyles'
import { db } from '../../core/firebase/firebase'
import { useParams } from 'react-router-dom'
import { Container, Grid, Paper, Typography, Table, TableRow, TableCell, TableHead, TableBody } from '@material-ui/core'
import clsx from 'clsx'
import { gameUtils } from '@kwiz/core'
import { KwizGameQuestion } from '@kwiz/core/dist/interfaces/igame'

interface GameDashboardProps {
  classes: any
}

const GameDashboard: React.FC<GameDashboardProps> = (props) => {
  const { classes } = props
  const [kwiz, setKwiz] = React.useState<any | undefined>(undefined)
  const [answers, setAnswers] = React.useState<any[] | undefined>(undefined)
  const [questions, setQuestions] = React.useState<any[] | undefined>(undefined)

  let { id } = useParams()

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight)

  /* Load Kwiz */
  React.useEffect(() => {
    const unscubscribeKwiz = db
      .collection('kwizzes')
      .doc(id)
      .onSnapshot(function (doc) {
        console.log('Loaded Kwiz')

        setKwiz(doc.data())
      })

    return () => {
      unscubscribeKwiz()
    }
  }, [id])

  /* Load questions */
  React.useEffect(() => {
    const unscubscribeQuestions = db
      .collection('kwizzes')
      .doc(id)
      .collection('questions')
      .onSnapshot((querySnapshot) => {
        let questions: KwizGameQuestion[] = []
        querySnapshot.forEach(function (doc) {
          questions.push({ ...doc.data(), id: doc.id } as KwizGameQuestion)
        })
        setQuestions(gameUtils.sortQuestions(questions))
      })

    return () => {
      unscubscribeQuestions()
    }
  }, [id])

  /* Load answers */
  React.useEffect(() => {
    const unscubscribeAnswers = db
      .collection('answers')
      .doc(id)
      .collection('users')
      .onSnapshot((querySnapshot) => {
        let answers: any[] = []
        querySnapshot.forEach(function (doc) {
          console.log('Loaded answer', doc.id)
          answers.push(doc.data())
        })
        setAnswers(answers)
      })

    return () => {
      unscubscribeAnswers()
    }
  }, [id])

  const findRightAnswer = (question: any): string | undefined => {
    for (let entry of question.answers) {
      if (entry && entry.secrets && entry.secrets.rightAnswer) {
        return entry.id
      }
    }
    return undefined
  }

  interface Stats {
    answers: number
    correctAnswers: number
  }

  const generateStats = (questionId: string, rightAnswer: string | undefined): Stats => {
    let stats = {
      answers: 0,
      correctAnswers: 0,
    }

    if (!answers) {
      return stats
    }

    for (let entry of answers) {
      for (let key of Object.keys(entry.answers)) {
        if (questionId === key) {
          stats.answers = stats.answers + 1
          if (entry.answers[key] === rightAnswer) {
            stats.correctAnswers = stats.correctAnswers + 1
          }
        }
      }
    }
    return stats
  }

  const renderQuestionRows = () => {
    return questions?.map((entry) => {
      const stats = generateStats(entry.id, findRightAnswer(entry))

      return (
        <TableRow key={'row-' + entry.id}>
          <TableCell>{entry.title}</TableCell>
          <TableCell align="right">{stats.answers}</TableCell>
          <TableCell align="right">
            {stats.answers > 0 ? (stats.correctAnswers / stats.answers) * 100 + '%' : '-'}
          </TableCell>
        </TableRow>
      )
    })
  }

  const renderQuestionTable = (questions: any[] | undefined) => {
    if (!questions) {
      return <div>Ingen spørsmål ble funnet. Her er det noe galt.</div>
    }

    return (
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Spørsmål</TableCell>
            <TableCell align="right">Antall svar</TableCell>
            <TableCell align="right">Resultat</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{renderQuestionRows()}</TableBody>
      </Table>
    )
  }

  if (!kwiz && !answers && !questions) {
    return <div>Loading...</div>
  }

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8} lg={9}>
          <Paper className={fixedHeightPaper}>
            <Typography variant="h3">{kwiz.title}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Paper className={clsx(fixedHeightPaper, classes.centered)}>
            <Typography variant="h1">{answers ? answers.length : 0}</Typography>
            <br /> spillere
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>{renderQuestionTable(questions)}</Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <pre>{JSON.stringify(answers)}</pre>
            <pre>{JSON.stringify(kwiz)}</pre>
            <pre>{JSON.stringify(questions, null, 2)}</pre>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      borderRadius: '10px',
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: 160,
    },
    centered: {
      textAlign: 'center',
    },
  })

export default withStyles(styles, { withTheme: true })(GameDashboard)
