import * as React from 'react'

import { Theme } from '@material-ui/core/styles/createMuiTheme'
import createStyles from '@material-ui/core/styles/createStyles'
import withStyles from '@material-ui/core/styles/withStyles'
import { Container, Typography, Toolbar } from '@material-ui/core'

interface NoMatchProps {
  classes: any
}

const NoMatch: React.FC<NoMatchProps> = (props) => {
  const { classes } = props
  return (
    <Container className={classes.root}>
      <Toolbar />
      <Typography variant="h4">Oisan! Her kom du vist litt feil avsted.</Typography>
      <Typography variant="body1">Klikk på logoen øverst til venstre for å komme til forsiden vår.</Typography>
    </Container>
  )
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: '50px',
      height: '100vh',
      textAlign: 'center',
    },
  })

export default withStyles(styles, { withTheme: true })(NoMatch)
