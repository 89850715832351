import { doSignIn, doSignOut } from '../firebase/auth'
import { push } from 'connected-react-router'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'

export const requestLogin = (page: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    doSignIn()
      .then(() => {
        setTimeout(() => {
          dispatch(push(page))
        }, 1000) // FIXME Should set an IS_LOGGING_IN state that router can wait for until logged in.
      })
      .catch(() => dispatch(push('/error')))
  }
}

export const requestLogout = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    doSignOut()
      .then(() => dispatch(push('/')))
      .catch(() => dispatch(push('/')))
  }
}
