import { Theme } from '@material-ui/core/styles/createMuiTheme'
import createStyles from '@material-ui/core/styles/createStyles'
import withStyles from '@material-ui/core/styles/withStyles'
import * as React from 'react'
import { Container } from '@material-ui/core'
//import { useDispatch } from 'react-redux'
//import { push } from 'connected-react-router'
//import JoinBox from './JoinBox'

interface IndexPageProps {
  classes: any
}

const IndexPage: React.FC<IndexPageProps> = (props: IndexPageProps) => {
  //const { classes } = props
  //const dispatch = useDispatch()

  return <Container>Index</Container>
}

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
      marginTop: theme.spacing(4),
    },
    container: {
      fontFamily: "'Gloria Hallelujah', cursive",
    },
    teaser: {},
  })

export default withStyles(styles, { withTheme: true })(IndexPage)
