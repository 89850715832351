import { ConnectedRouter } from 'connected-react-router'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import { ReactReduxFirebaseProvider } from './core/firebase'
import * as Store from './core/store'
import App from './modules/App'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(
  <React.Fragment>
    <Provider store={Store.store}>
      <ReactReduxFirebaseProvider {...Store.reactReduxFirebaseProps}>
        <ConnectedRouter history={Store.history}>
          <App />
        </ConnectedRouter>
      </ReactReduxFirebaseProvider>
    </Provider>
  </React.Fragment>,
  document.getElementById('root')
)

// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
